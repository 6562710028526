import React from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import PrivateRouteLogin from './components/PrivateRouteLogin';
import Home from './pages/Home';

function App() {
  return (
    <div>
      <Toaster position = 'top-center' />
      <BrowserRouter>
        <Routes>
          <Route path = '/' element = { <Home /> } />
          { /* <Route path = '/horse-products' element = { <HorseProducts /> } />
          <Route path = '/rider' element = { <Rider /> } />
          <Route path = '/signup' element = { <SignUp /> } />
          <Route path = '/password/reset-link' element = { <PasswordResetLink /> } />
          <Route path = '/password/reset-form' element = { <PasswordResetForm /> } /> */ }
          
          <Route path = '' element = { <PrivateRouteLogin /> }>
            { /* <Route path = '/login' element = { <Login /> } />
            <Route path = '/forgot-password' element = { <LostPassword /> } />
            <Route path = '/verify-otp' element = { <VerifyOtp /> } />
            <Route path = '/password/reset-form' element = { <PasswordResetForm /> } /> */ }
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
