import React from 'react';
import { Link } from 'react-router-dom';
import Android from '../images/adrioid.png';
import Apple from '../images/apple.png';
import logo from '../images/khayyal_K_logo.png';
import master from '../images/master.png';


const Footer = () => {

  return(
    <>
      <div className = 'bg-black'>
        <div className = 'container mx-auto px-5 lg:px-16'>
          <div className = 'py-10 block lg:flex justify-between'>
            <div className = 'w-full lg:w-width23%'>
              <Link className = 'block w-11'>
                <img src = { logo } alt = 'logo' />
              </Link>
              <ul className = 'text-white mt-5'>
                <li><Link className = 'text-sm'>Terms & Conditions</Link></li>
                <li><Link className = 'text-sm inline-block mt-2'>Privacy Policy</Link></li>
                <li><Link className = 'text-sm inline-block mt-2'>Sitemap</Link></li>
                <li><Link className = 'text-sm inline-block mt-2'>Help | Faq</Link></li>
                <li><Link className = 'text-sm inline-block mt-2'>Contact</Link></li>
                <li><Link className = 'text-sm inline-block mt-2'>Data Deletion Policy</Link></li>
              </ul>
            </div>
            <div className = 'w-full lg:w-width77% mt-5 lg:mt-0'>
              <div className = 'block lg:flex justify-between'>
                <div className = 'text-white'>
                  <h3 className = 'text-sm xl:text-base font-bold'>We Accept</h3>
                  <div className = 'mt-3'>
                    <img src = { master } alt = 'visa' />
                  </div>
                </div>
                <div className = 'mt-5 lg:mt-0'>
                  <h3 className = 'text-sm xl:text-base font-bold text-white'>Shop On The Go With Our App</h3>
                  <div className = 'flex md:block mt-3'>
                    <Link to = "#"><img className = 'w-32' src = { Apple } alt = 'apple' /></Link>
                    <Link to = "#" className = 'inline-block ml-2 md:ml-0 mt-0 md:mt-2'><img className = 'w-32' src = { Android } alt = 'android' /></Link>
                  </div>
                </div>
                { /* <div className = 'mt-5 lg:mt-0'>
                  <h3 className = 'text-sm xl:text-base font-bold text-white'>Connect</h3>
                  <ul className = 'flex mt-3'>
                    <li className = 'mr-2'>
                      <Link className = 'flex items-center justify-center w-11 h-11  bg-black border border-stokecolor rounded-full '><img className = 'w-6' src = { twitter } alt = 'twitter' /></Link>
                    </li>
                    <li className = 'mr-2'>
                      <Link className = 'flex items-center justify-center w-11 h-11  bg-black border border-stokecolor rounded-full '><img className = 'w-6' src = { instagram } alt = 'twitter' /></Link>
                    </li>
                    <li className = 'mr-2'>
                      <Link className = 'flex items-center justify-center w-11 h-11  bg-black border border-stokecolor rounded-full '><img className = 'w-6' src = { linkedin } alt = 'twitter' /></Link>
                    </li>
                    <li className = 'mr-2'>
                      <Link className = 'flex items-center justify-center w-11 h-11  bg-black border border-stokecolor rounded-full '><img className = 'w-6' src = { facebook } alt = 'twitter' /></Link>
                    </li>
                    <li>
                      <Link className = 'flex items-center justify-center w-11 h-11  bg-black border border-stokecolor rounded-full '><img className = 'w-6' src = { snapchart } alt = 'twitter' /></Link>
                    </li>
                  </ul>
                </div> */ }
              </div>
              <div className = 'block lg:flex justify-between pt-10 lg:pt-20'>
                <div className = 'w-full md:w-width48%'>
                  <Link className = 'text-base text-center font-bold text-white border border-stokecolor rounded-full block w-full px-4 py-2.5' to = "mailto:info@khayyal.me">Email us: info@khayyal.me</Link>
                </div>
                <div className = 'w-full md:w-width48% mt-5 lg:mt-0'>
                  <Link className = 'text-base text-center font-bold text-white border border-stokecolor rounded-full block w-full px-4 py-2.5' to = "tel:966 55 352 7777">Call Us: +966 55 352 7777</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className = 'border-t border-stokecolor py-8'>
          <div className = 'container mx-auto px-5 lg:px-16'>
            <p className = 'text-sm text-white text-left lg:text-center'>© 2024 The Khayyal. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;