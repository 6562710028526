import React from 'react';
import { Helmet } from 'react-helmet';
import BannerHome from '../components/BannerHome';
import Footer from '../components/Footer';
// import Gallerybox from '../components/Gallerybox';
import Header from '../components/Header';
import Intro from '../components/Intro';
// import Quantitymeetspassion from '../components/Quantitymeetspassion';
// import Services from '../components/Services';


const Home = () => {
  return (
    <div>
      <Helmet>
        <meta charset = "utf-8" />
        <title>Khayyal</title>
        <meta name = "description" content = "Khayaal" />
        <meta name = "author" content = "Khayaal" />
        <link rel = "canonical" href = "#" />
      </Helmet>
      <Header />
      <BannerHome />
      <div className = 'container mx-auto px-5 xl:px-16'>
        { /* <Quantitymeetspassion />
        <Services /> */ }
        { /* <Gallerybox /> */ }
        <Intro />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
