import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import i18n from 'i18next';
import React, { useState } from 'react';
import { initReactI18next } from 'react-i18next';
import arabicFlag from '../images/arabicimg.svg';
import englishFlag from '../images/englishimg.png';
import tAr from '../language/ar/Arabic.json';
import tEn from '../language/en/EnglishLanguage.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: tEn },
    ar: { translation: tAr }
  },
  lng: localStorage.getItem('selectLanguage') || 'en',
  fallbackLng: 'en',
  interpolation: { escapeValue: false }
});

const Multilanguage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectLanguage') || 'en');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLanguageChange = (language) => {
    localStorage.setItem('selectLanguage', language);
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const options = [
    { value: 'en', label: 'English', flag: englishFlag },
    { value: 'ar', label: 'Arabic', flag: arabicFlag }
  ];

  return (
    <div className = "custom-select">
      <div
        className = "selected-option flex justify-between"
        onClick = { () => setIsDropdownOpen(!isDropdownOpen) }
      >
        <div className = 'flex'>
          <img
            src = { options.find(opt => opt.value === selectedLanguage).flag }
            alt = { selectedLanguage }
            style = { { width: '20px', marginRight: '8px' } }
          />
          <span className = 'text-white'>{ options.find(opt => opt.value === selectedLanguage).label }</span>
        </div>
        <span className = 'text-white'><KeyboardArrowDownOutlinedIcon /></span>
      </div>
      { isDropdownOpen && (
        <ul className = "options">
          { options.map(option => (
            <li
              key = { option.value }
              onClick = { () => handleLanguageChange(option.value) }
            >
              <img
                src = { option.flag }
                alt = { option.label }
                style = { { width: '20px', marginRight: '8px' } }
              />
              { option.label }
            </li>
          )) }
        </ul>
      ) }
    </div>
  );
};

export default Multilanguage;