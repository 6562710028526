import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
const getToken = () => localStorage.getItem('token');
export const recomendedproductApi = createApi({
  reducerPath: 'recomendedproductApi',
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: (builder) => ({
    recomended: builder.query({
      query: () => '/store/product/recommended/'
    }),
    recentlyview: builder.query({
      query: () => '/store/product/recently-viewed/'
    }),
    bestseller: builder.query({
      query: () => '/store/product/bestsellers/'
    }),
    trendingitem: builder.query({
      query: () => '/store/product/trending-items/'
    }),
    popularitem: builder.query({
      query: () => '/store/product/popular-items/'
    }),
    newlyaddeditem: builder.query({
      query: () => '/store/product/newly-added-items/'
    }),
    instagramfeed: builder.query({
      query: () => 'https://graph.instagram.com/me/media?fields=id,media_type,media_url,thumbnail_url,caption&access_token=IGQWRPVlNLc0pXLUlodDNZATXVwTTJzTndfWlRQR2ZAhNlhCVVZAfWC1QUGF1ZAzNsa1VZARmI3SHY4Vzk1YzVmclVlYjY3SjVlMnVGSW0wN1RuSnFQb01pNzVIemtWbTVkNlkzdlhlTEtIZAG05MWcyUDhKdEhsbW9hbVEZD'
    }),
    categorycollection: builder.query({
      query: () => '/store/product/category-list/'
    }),
    brandcollection: builder.query({
      query: () => '/store/product/brand-list/'
    }),
    FilterProducts: builder.mutation({
      query({ body, size, page }) {
        return {
          url:`/store/products/filter?size=${size}&page=${page}`,
          method: 'POST',
          body
        };
      }
    }),
    bloglist: builder.query({
      query: () => '/blogs/all'
    }),
    blogDetails: builder.query({
      query: (id) => `/blog-details/${id}`
    }),
    relatedBlog: builder.mutation({
      query(body) {
        return {
          url: '/blogs/related-posts/',
          method: 'POST',
          body
        };
      }
    }),
    leaveblogcomment: builder.mutation({
      query(body) {
        const token = getToken();
        return {
          url: '/blogs/add-comment/',
          method: 'POST',
          body,
          headers: token ? { Authorization: `Bearer ${token}` } : {}
        };
      }
    }),
    bannerlist: builder.query({
      query: () => '/store/admin/list-banner/'
    }),
    homecatloglist: builder.query({
      query: () => '/store/admin/list-home-category/'
    }),
    giftcardlist: builder.query({
      query: () => '/store/admin/list-gift-and-gift-card/'
    }),
    saleofferlist: builder.query({
      query: () => '/store/admin/list-sale-and-offers/'
    }),
    fragrancelist: builder.query({
      query: () => '/store/admin/fragrance/'
    }),
    testimoniallist: builder.query({
      query: () => '/store/admin/list-testimonials/'
    }),
    scentfamilylist: builder.query({
      query: () => '/store/admin/fragrance-family/'
    }),
    navigationlist: builder.query({
      query: () => '/store/admin/list-navigation/'
    })
  })
});

export const {
  useRecomendedQuery,
  useRecentlyviewQuery,
  useBestsellerQuery,
  useTrendingitemQuery,
  usePopularitemQuery,
  useNewlyaddeditemQuery,
  useInstagramfeedQuery,
  useCategorycollectionQuery,
  useBrandcollectionQuery,
  useFilterProductsMutation,
  useBloglistQuery,
  useBlogDetailsQuery,
  useRelatedBlogMutation,
  useLeaveblogcommentMutation,
  useBannerlistQuery,
  useHomecatloglistQuery,
  useGiftcardlistQuery,
  useSaleofferlistQuery,
  useFragrancelistQuery,
  useTestimoniallistQuery,
  useScentfamilylistQuery,
  useNavigationlistQuery
} = recomendedproductApi;