/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';



const Intro = () => {

  const [tab, setTab] = useState(1);

  const handletab = (item) => {
    setTab(item);
  };

  return(
    <>
      <div className = 'pt-10'>
        <ul>
          <li>
            <h3 className = 'text-black text-xl lg:text-2xl font-bold uppercase'>Introduction</h3>
            <p className = 'text-sm text-blacklight mt-1'>In the Kingdom of Saudi Arabia, equestrianism is more than a sport; it's a deep-rooted tradition and a way of life. Yet, the equestrian market in KSA remains fragmented and unorganized, making it challenging for enthusiasts, clubs, and event organizers to connect and thrive. Khayyal platform is here to change that.</p>
          </li>
          <li className = 'mt-6'>
            <h3 className = 'text-black text-xl lg:text-2xl font-bold uppercase'>Our Purpose</h3>
            <p className = 'text-sm text-blacklight mt-1'>We are on a mission to revolutionize the equestrian market in KSA by creating a unified platform that brings together all aspects of equestrian life—whether you are a rider, a club owner, or an event organizer. We aim to streamline operations, enhance visibility, and foster a thriving community.</p>
          </li>
          <li className = 'mt-6'>
            <h3 className = 'text-black text-xl lg:text-2xl font-bold uppercase'>The Problem</h3>
            <p className = 'text-sm text-blacklight mt-1'>The equestrian market in KSA is currently plagued by disorganization and inefficiencies. Clubs operate in isolation, event management is cumbersome, and riders often struggle to find the resources they need. This lack of coordination hinders the growth of the industry and prevents it from reaching its full potential.</p>
          </li>
          
        </ul>

        <div className = 'pt-10 flex justify-between md:block'>
          <button onClick = { () => handletab(1) } className = { `inline-block  md:w-auto ${tab === 1?'bg-black text-white': 'bg-white text-black '} text-xs lg:text-base font-bold px-2 md:px-8 py-2.5 rounded` }>Our Solution</button>
          <button onClick = { () => handletab(2) } className = { `inline-block  md:w-auto ${tab === 2?'bg-black text-white': 'bg-white text-black '} text-xs lg:text-base font-bold px-2 md:px-8 py-2.5 rounded` }>Why We Stand Out</button>
          <button onClick = { () => handletab(3) } className = { `inline-block  md:w-auto ${tab === 3?'bg-black text-white': 'bg-white text-black '} text-xs lg:text-base font-bold px-2 md:px-8 py-2.5 rounded` }>Our Vision</button>
        </div>

        <div className = 'pt-3 pb-10'>
          {
            tab === 1 && <div>
              <p className = 'text-sm text-blacklight'>Khayyal platform is the first of its kind in KSA, designed specifically to address these challenges:</p>
              <ul className = 'ml-5'>
                <li className = 'mt-3'>
                  <p className = 'text-sm text-blacklight'>1.	We provide a comprehensive dashboard for clubs to manage their operations, track finances, and connect with riders.</p>
                </li>
                <li className = 'mt-3'>
                  <p className = 'text-sm text-blacklight'>2.	Event organizers can easily manage every aspect of their events, from ticketing to competition management, all in one place.</p>
                </li>
                <li className = 'mt-3'>
                  <p className = 'text-sm text-blacklight'>3.	Riders and enthusiasts can discover clubs, events, and services effortlessly, fostering a more connected equestrian community.</p>
                </li>
                <li className = 'mt-3'>
                  <p className = 'text-sm text-blacklight'>4.	We make it simple for new clubs and event companies to join the platform, ensuring that all participants can benefit from a unified system.</p>
                </li>
              </ul>
            </div>
          }
          {
            tab === 2 && <p className = 'text-sm text-blacklight'>We are more than just a platform; we are a movement. By bringing organization and structure to the equestrian market, we are empowering everyone involved to achieve more. Whether you're looking to grow your club, host a world-class event, or simply find your next riding adventure, our platform is the key to unlocking new opportunities.</p>
          }

          {
            tab === 3 && <p className = 'text-sm text-blacklight'>To be the leading equestrian platform in KSA, unifying the market and setting new standards for excellence. We envision a future where the equestrian community is fully connected, thriving, and accessible to all.</p>
          }
        </div>
      </div>
    </>
  );
};

export default Intro;