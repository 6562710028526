import { createSlice } from '@reduxjs/toolkit';

const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    message: '',
    showToast: false
  },
  reducers: {
    showToast: (state, action) => {
      state.message = action.payload;
      state.showToast = true;
    },
    hideToast: (state) => {
      state.showToast = false;
      state.message = '';
    }
  }
});

export const { showToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
