import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import slider1 from '../images/slider1.png';

const BannerHome = () => {


  const data = [
    {
      slideimg: slider1
    },
    {
      slideimg: slider1
    }
  ];


  const sliderRef = useRef(null);

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const settings = {
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    cssEase: 'linear'
  };

  return (
    <div>
     
      <div className = 'relative'>
        <Slider ref = { sliderRef } { ...settings }>
          {
            data?.map((item) => (
              <div key = { item?.id }>
                <div to = "#" className = 'block'>
                  <img
                    className = "w-full"
                    src = { item?.slideimg }
                    alt = "Banner 2"
                  />
                </div>
              </div>
            ))
          }
        </Slider>
        <div className = 'w-full absolute top-1/3'>
          <button onClick = { goToPrevSlide } className = 'w-10 h-10 ml-5 lg:ml-16 bg-[252525] text-white border border-borderColor shadow-arrowsad rounded-full block absolute left-0'><KeyboardArrowLeftOutlinedIcon /></button>
        </div>
        <div className = 'w-full absolute top-1/3'>
          <button onClick = { goToNextSlide } className = 'w-10 h-10 mr-5 lg:mr-16 bg-[252525] text-white border border-borderColor shadow-arrowsad rounded-full block absolute right-0'><KeyboardArrowRightOutlinedIcon /></button>
        </div>
      </div>
      { /* <div className = 'block md:hidden'>
        <Slider ref = { sliderRef } { ...settings }>

          {
            data?.map((item) => (
              <div key = { item?.id }>
                <div to = "#" className = 'block'>
                  <img
                    className = "w-full"
                    src = { item?.slideimg }
                    alt = "Banner 2"
                  />
                </div>
              </div>
            ))
          }
           
        </Slider>
      </div> */ }
      
    </div>
  );
};

export default BannerHome;
