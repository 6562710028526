import CloseIcon from '@mui/icons-material/Close';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import carticon from '../images/cart.svg';
import logo from '../images/khayyal_K_logo.png';
import Multilanguage from './Multilanguage';

const Header = () => {
  // const selectLanguage = localStorage.getItem('selectLanguage');
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <div className = 'border-b border-headerborder bg-black sticky top-0 z-40'>
        <div className = { 'container mx-auto px-5 xl:px-16 py-1 flex justify-between text-black relative ' }>
          <div className = 'hidden self-center lg:block w-width5%'>
            <Link className = 'block w-12' to = '/' ><img className = 'w-full' src = { logo } alt = 'logo' /></Link>
          </div> 
          <div className = 'self-center block lg:hidden'>
            <span className = 'text-center mx-auto mt-3 text-white' onClick = { toggleMenu } ><MenuOutlinedIcon style = { {fontSize: '28px'} } /></span>
          </div>
          <div className = 'block self-center lg:hidden'>
            <Link className = 'block w-9' to = '/' ><img src = { logo } alt = 'logo' /></Link>
          </div>
          { /* <div className = 'hidden lg:flex  self-center justify-end w-width65%'>
            <ul className = 'flex m-0 p-0 list-none gap-x-4 text-black text-sm'>
              <li>
                <Link className = ' text-sm block text-white px-1 py-2' to = "#">Horse</Link>
              </li>
              <li>
                <Link className = ' text-sm block text-white px-1 py-2 ' to = "#">Health & Care</Link>
              </li>
              <li>
                <Link className = ' text-sm block text-white px-1 py-2 ' to = "#">Stable</Link>
              </li>
              <li>
                <Link className = ' text-sm block text-white px-1 py-2 ' to = "#">Services</Link>
              </li>
              <li>
                <Link className = ' text-sm block text-white px-1 py-2 ' to = "#">About</Link>
              </li>
              <li>
                <div className = 'w-56 self-center relative hidden lg:block'>
                  <SearchMain />
                </div>
              </li>
            </ul>
          </div> */ }
          
          <div className = 'self-center flex justify-end w-auto md:w-width30%'>
            <ul className = 'flex m-0 p-0 list-none gap-x-4 text-black text-sm'>
              { /* <li className = 'relative hidden lg:block self-center'>
                <Link className = ' text-sm block text-white px-1 py-2 uppercase ' to = "#">LOGIN / REGISTER</Link>
              </li> */ }
              { /* <li className = 'block lg:hidden'>
                <SearchMainMobile />
              </li> */ }
              <li className = 'self-center'>
                <Link className = ' text-sm flex self-center text-white bg-darkgray w-9 h-9  md:w-11 md:h-11 rounded-full px-1 py-2 relative' to = "#">
                  <img className = 'w-5 mx-auto' src = { carticon } alt = 'carticon' />
                  <div className = 'w-4 h-4 rounded-full bg-darkred text-white text-center text-xs absolute right-0 top-0'>0</div>
                </Link>
              </li>
              <li className = 'self-center hidden md:block'>
                <div>
                  <Multilanguage />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className = { `mobile-menu max-h-screen ${
        isMenuOpen ? 'slide-in' : 'slide-out'
      } transition-transform duration-300 drop-shadow-lg` } ref = { menuRef }>
        <div>
          <p className = 'flex justify-end'><CloseIcon onClick = { toggleMenu } style = { { fontSize: '25px', color: '#fff' } } /></p>
          <ul className = 'fixed h-screen w-width90% overflow-x-auto'>
            <li>
              <Multilanguage />
            </li>
            { /* <li className = 'mt-5 border-t border-borderColor py-3'><Link className = { 'block text-white text-lg' } to = "#">Horse</Link> </li>
            <li className = { 'border-t border-borderColor py-3' }><Link className = 'block text-white text-lg' to = "#">Health & Care</Link> </li>
            <li className = { 'border-t border-borderColor py-3' }><Link className = 'block text-white text-lg' to = "#">Stable</Link> </li>
            <li className = { 'border-t border-borderColor py-3' }><Link className = 'block text-white text-lg' to = "#">Services</Link> </li>
            <li className = { 'border-t border-borderColor py-3' }><Link className = 'block text-white text-lg' to = "#">About</Link> </li>
            <li className = { 'border-t border-borderColor py-3' }><Link className = 'block text-white text-lg' to = "#">Login</Link> </li>
            <li className = { 'border-t border-borderColor py-3' }><Link className = 'block text-white text-lg' to = "#">Register</Link> </li>
            */ }
          </ul>
        </div>
      </div>
      
    </>
  );
};

export default Header;